import React from "react"
import Header from "../../components/header/header"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageGallery from "../../components/gallery/gallery-component"
import { mintstudiophotos } from "../../components/photos/mint-studios"
import "../../../styles/global-styles.scss"

const MintStudios = () => (
  <Layout>
    <Header />
    <SEO
      title="Mint Studios Photoshoot"
      description="YES Inspired Events is a company that believes in providing a quality, fun & energetic event. We market and create events that inspire people to be passionate about life’s greatest experiences. From an intimate soiree to a lavish gala affair, we’re passionate about your vision becoming a reality."
    />
    <h1 className="gallery-photos-heading">Mint Studios Photoshoot</h1>
    <p className="gallery-photos-text-body">
      YES Inspired style shoot at Mint Studios. YESInspired is a company that
      believes in providing a quality, fun & energetic event. We market and
      create events that inspire people to be passionate about life’s greatest
      experiences.
    </p>
    <ImageGallery photos={mintstudiophotos} />
  </Layout>
)

export default MintStudios
