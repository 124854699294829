export const mintstudiophotos = [
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0249.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0328.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0352.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0369.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0381.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0420.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0422.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0458.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0467.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0489.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0534.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0565.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0640.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0579.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Mint+Studios/RBN_0608.jpg",
    width: 4,
    height: 3,
  },
]
